@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --highLight: #EF573C;
    --textColor: #EEEAEA;
    --dscColor: #DBD7D7;
    --btnColor: #0B85D3;
    --borderColor : #D3150B;
    --bg1 : #050d1d;
  }

body{
    background-color: var(--bg1);
}


.bars {
    display: none !important;
}


.hero{
    height: 450px !important;
    margin-bottom:40px ;
    margin: 50px;
}

.hero-img{
    background-image: url('/public/photo/hero.png');
}

.show-case-1{
    background-position: center;
    background-attachment:fixed;
    background-size: cover;
    position: relative;

}
.show-case-1::after{
     content: '';
     position: absolute;
     top: 0px;
     left: 0px;
     height: 100%;
     transition: all 0.5s ease-in-out;
     width: 100%;
     background-color: rgba(4, 15, 50, 0.489);
}
.show-case-1:hover::after{
    content: '';
    position: absolute;
    top: 0px;
    transition: all 0.5s ease-in-out;
    left: 0px;
    height: 100%;
    width: 0px;
    background-color: rgba(4, 15, 50, 0.489);
}

.img{
    transition: all 0.5s ease-in-out;
    transform: scale(0.7);
}
.img:hover{
    transition: all 0.7s ease-in-out;
    transform: scale(1);
}
.show-case-2{
    background-position: center;
    background-attachment:fixed;
    background-size: cover;
    position: relative;

}
.show-case-2::after{
     content: '';
     position: absolute;
     top: 0px;
     left: 0px;
     height: 100%;
     transition: all 0.5s ease-in-out;
     width: 100%;
     background-color: rgba(4, 15, 50, 0.489);
}
.show-case-2:hover::after{
    content: '';
    position: absolute;
    top: 0px;
    transition: all 0.5s ease-in-out;
    left: 0px;
    height: 100%;
    width: 0px;
    background-color: rgba(4, 15, 50, 0.489);
}



.show-case-3{
    background-position: center;
    background-attachment:fixed;
    background-size: cover;
    position: relative;

}
.show-case-3::after{
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    transition: all 0.5s ease-in-out;
    width: 100%;
    background-color: rgba(4, 15, 50, 0.489);
}


.show-case-3:hover::after{
    content: '';
    position: absolute;
    top: 0px;
    transition: all 0.5s ease-in-out;
    left: 0px;
    height: 100%;
    width: 0px;
    background-color: rgba(4, 15, 50, 0.489);
}




.work h2{
    border-radius: 10px;
    background: #091f53;
    box-shadow:  20px 20px 60px #081a47,
                -20px -20px 60px #0a245f;
    padding:10px;
    color: var(--textColor)
             
}

.code-block{
    border-radius: 5px;
    background: #091f53;
    box-shadow:  20px 20px 60px #081a47,
                -20px -20px 60px #0a245f;
}
.code-block-pro{
    border-radius: 5px;
    background: #083c6d;
    box-shadow:  20px 20px 60px #07335d,
                -20px -20px 60px #09457d;
}


.person{
    width: 150px;
    height: 150px;
    border-radius: 50%;
}

.person img{
    width: 100%;
    height: 100%;
    border-radius: 100%;
}

.team-person {
    transition: all 0.5s ease-in;
    transform: scale(0.9);
    cursor:auto;
}

.team-person:hover {
    transform: scale(1);
    transition: all 0.5s ease;
}

button{
    cursor: pointer;
}

.icon{
    cursor: pointer;
    transform: scale(.9);
    transition: all 0.5s ease-in;
}
.icon:hover{
    transform: scale(1);
    transition: all 0.5s ease;
}


.about img{
    background-image: url('../public/shape/shape-8.png');
    background-position: center;
    background-size: cover;
}




@media screen and  (max-width: 500px){
    .none{
        height: 0px !important;
        overflow: hidden;
        transition: all 0.5s ease-in;
    }
    .blocks{
        height: 200px !important;
        transition: all 0.5s ease-in;
    }

.bars {
    display: block !important;
}
.contact{
    width: 100% !important;
    padding: 5px 10px;
}
.col-md-6 , .col-md-4{
    width: 100% !important;
    margin: 0px !important;
    padding: 0px !important;
}

.social{
   margin:auto !important;
}
form {
    width: 100% !important;
    padding: 20px;
}
.work .w-20{
    width: 20%!important;
}
.work img {
    width: 100px !important;
    max-width: 50px;
    display: inline-block;
}
.head{
    display: flex;
    justify-content: space-between !important;
}

.navbar{
    display: flex !important;flex-direction: column;
}
.getin{
    display: none !important;
}
.abouts{
    margin-top: 600px !important;
    margin: auto;
}
.team-worker{
    margin: auto !important;
}
.team{
    margin: 0px !important;
}

.team .col-md-3{
    width: 100%;
}
.team-group {
    margin: 0px !important;
}
.info{
    margin: 0px!important;
    padding: 10px!important;
}


}

